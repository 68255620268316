import $api from "@/http"
import {AxiosResponse} from "axios";
import {ILabelCategory} from "@/models/ILabelCategory";
import {ILabel} from "@/models/ILabel";

const LabelTemplateCategoryServiceEndPoint = '/label-category'
export class LabelTemplateCategoryService {
  static getCategoriesList(hotel_id:number):Promise<AxiosResponse<ILabelCategory[]>>{
    return $api.get<ILabelCategory[]>(`/label-categories/${hotel_id}`)
  }
  static getCategory(id:number):Promise<AxiosResponse<ILabelCategory>>{
    return $api.get<ILabelCategory>(`${LabelTemplateCategoryServiceEndPoint}/${id}`)
  }
  static putCategory(category:ILabelCategory):Promise<AxiosResponse<ILabelCategory>>{
    return $api.put<ILabelCategory>(`${LabelTemplateCategoryServiceEndPoint}/${category.id}`,category);
  }
  static postCategory(category:ILabelCategory):Promise<AxiosResponse<ILabelCategory>>{
    return $api.post<ILabelCategory>(`${LabelTemplateCategoryServiceEndPoint}`,category);
  }
  static deleteCategory(id:number):Promise<AxiosResponse<ILabelCategory>>{
    return $api.put<ILabelCategory>(`${LabelTemplateCategoryServiceEndPoint}/${id}`);
  }
}

const LabelTemplateServiceEndPoint = `/label`
export class LabelTemplateService{
  static getLabelListByCategory(category_id:number):Promise<AxiosResponse<ILabel[]>>{
    return $api.get<ILabel[]>(`/labels/${category_id}`);
  }
  static getCategory(id:number):Promise<AxiosResponse<ILabel>>{
    return $api.get<ILabel>(`${LabelTemplateServiceEndPoint}/${id}`)
  }
  static putCategory(label:ILabel):Promise<AxiosResponse<ILabel>>{
    return $api.put<ILabel>(`${LabelTemplateServiceEndPoint}/${label.id}`,label);
  }
  static post(label:ILabel):Promise<AxiosResponse<ILabel>>{
    return $api.post<ILabel>(`${LabelTemplateServiceEndPoint}`,label);
  }
  static deleteCategory(id:number):Promise<AxiosResponse<ILabel>>{
    return $api.put<ILabel>(`${LabelTemplateServiceEndPoint}/${id}`);
  }
}
