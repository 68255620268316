
import { Module } from "vuex-smart-module";
import slotModuleState from "@/store/modules/slotModule/state";
import slotModuleGetters from "@/store/modules/slotModule/getters";
import slotModuleMutations from "@/store/modules/slotModule/mutations";
import slotModuleActions from "@/store/modules/slotModule/actions";
export const slotModule = new Module({
    state: slotModuleState,
    getters: slotModuleGetters,
    mutations: slotModuleMutations,
    actions: slotModuleActions,
});
