import snapshotModuleState from "@/store/modules/snapshotModule/state";
import snapshotModuleGetters from "@/store/modules/snapshotModule/getters";
import snapshotModuleMutations from "@/store/modules/snapshotModule/mutations";
import snapshotModuleActions from "@/store/modules/snapshotModule/actions";
import { Module } from "vuex-smart-module";
export const snapshotModule = new Module({
  state: snapshotModuleState,
  getters: snapshotModuleGetters,
  mutations: snapshotModuleMutations,
  actions: snapshotModuleActions,
});
