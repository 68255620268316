import {Mutations} from "vuex-smart-module";
import {ILiteLabel} from "@/models/ILiteLabel";
import liteLabelModuleState from "@/store/modules/liteLabelModule/state";

export default class liteLabelModuleMutations extends Mutations<liteLabelModuleState>{
  setLoading(value:boolean){
    this.state.loading = value
  }
  setLiteLabels(labels:ILiteLabel[]){
    this.state.liteLabels = labels;
  }
  setLiteLabel(label:ILiteLabel){
    this.state.liteLabel = label;
  }
  addLiteLabel(label:ILiteLabel){
    this.state.liteLabels.push(label);
  }
  editLiteLabel(label:ILiteLabel){
    const candidate = this.state.liteLabels.find(x => x.id === label.id);
    if(candidate){
      const candidateIndex = this.state.liteLabels.indexOf(candidate);
      if(candidateIndex>=0){
        this.state.liteLabels.splice(candidateIndex,1,label);
      }
    }
  }
  deleteLiteLabel(label:ILiteLabel){
    const candidate = this.state.liteLabels.find(x => x.id === label.id);
    if(candidate){
      const candidateIndex = this.state.liteLabels.indexOf(candidate);
      if(candidateIndex>=0){
        this.state.liteLabels.splice(candidateIndex,1);
      }
    }
  }
}
