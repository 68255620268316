<template>
  <div style="padding: 40px; display: flex; justify-content: space-evenly">
    <div>
      <h5>Number of hotel</h5>
      <CListGroup style="min-width: 400px">
        <CListGroupItem>
          Free Hotels: {{ statistics.freeHotels }}
        </CListGroupItem>
        <CListGroupItem>
          Standart Hotels: {{ statistics.standartHotels }}
        </CListGroupItem>
        <CListGroupItem>
          Profi Hotels: {{ statistics.profiHotels }}
        </CListGroupItem>
        <CListGroupItem>
          All Hotels: {{ statistics.allHotels }}
        </CListGroupItem>
      </CListGroup>
    </div>
    <div>
      <h5>Number of users</h5>
      <CListGroup style="min-width: 400px">
        <CListGroupItem> All Users: {{ statistics.allUsers }} </CListGroupItem>
      </CListGroup>
    </div>
    <div>
      <h5>Created today</h5>
      <CListGroup style="min-width: 400px">
        <CListGroupItem> Users: {{ statistics.lastUsers }} </CListGroupItem>
        <CListGroupItem> Hotels: {{ statistics.lastHotels }} </CListGroupItem>
      </CListGroup>
    </div>
  </div>
</template>
<script>
import { CListGroup, CListGroupItem } from "@coreui/vue/src";
import $api from "@/http";
export default {
  data() {
    return {
      statistics: {},
    };
  },
  components: {
    CListGroup,
    CListGroupItem,
  },
  computed: {},
  async mounted() {
    const { data } = await $api.get(`system-statistic`);
    this.statistics = data;
  },
};
</script>
<style lang="scss">
.mainTable {
  &Container {
    padding-top: 15px;
  }
}
</style>
