import {ILiteLabel} from "@/models/ILiteLabel";
import {AxiosResponse} from "axios";
import $api from "@/http";

const end_point = `/admin-lite-label`;
export class LiteLabelService{
  static getLiteLables():Promise<AxiosResponse<ILiteLabel[]>>{
    return $api.get<ILiteLabel[]>(`/admin-lite-labels`)
  }
  static getLiteLabel(id:number):Promise<AxiosResponse<ILiteLabel>>{
    return $api.get<ILiteLabel>(`${end_point}/${id}`)
  }
  static postLiteLabel(label:ILiteLabel):Promise<AxiosResponse<ILiteLabel>>{
    return $api.post<ILiteLabel>(`${end_point}`,label)
  }
  static editLiteLabel(label:ILiteLabel):Promise<AxiosResponse<ILiteLabel>>{
    return $api.put<ILiteLabel>(`${end_point}/${label.id}`,label)
  }
  static deleteLiteLabel(id:number):Promise<AxiosResponse<ILiteLabel>>{
    return $api.delete<ILiteLabel>(`${end_point}/${id}`);
  }
}
