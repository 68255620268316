import $api from "@/http";
import { AxiosResponse } from "axios";
import { IHotel } from "@/models/IHotel";

export default class HotelService {
  static getHotelList(): Promise<AxiosResponse<IHotel[]>> {
    return $api.get("/list/Hotel");
  }
  static editHotel(payload: {
    id: number;
    hotel: IHotel;
  }): Promise<AxiosResponse<IHotel>> {
    return $api.patch(`/update/Hotel/${payload.id}`, payload.hotel);
  }
  static deleteHotel(id: number): Promise<AxiosResponse<IHotel>> {
    return $api.delete(`/delete/Hotel/${id}`);
  }
  static uploadHotelJson(payload: {
    hotel_id: number;
    file: File;
  }): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.append("hotel_id", `${payload.hotel_id}`);
    formData.append("file", payload.file, payload.file.name);
    return $api.post(`/upload-hotel-data`, formData);
  }
  static getHotelJson(id: number): Promise<AxiosResponse> {
    return $api.get(`/download-hotel-data/${id}`);
  }
  static getHotelStatistics(): Promise<AxiosResponse> {
    return $api.get("/get-chart-by-day/hotels");
  }
  static getZipImages(id: number): Promise<AxiosResponse<string>> {
    return $api.get(`/zip/${id}`);
  }
  static changeUser(payload:{
      hotel_id:number;
      user_id:number;
  }):Promise<AxiosResponse>{
      return $api.get(`/clone-hotel/${payload.hotel_id}/${payload.user_id}`);
  }
}
