import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import LoginView from "@/views/LoginView.vue";
import MainView from "@/views/MainView.vue";
import HotelList from "@/components/HotelListComponent.vue";
import UserList from "@/components/UserListComponent.vue";

import store from "@/store";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
  },
  {
    path: "/",
    name: "MainView",
    component: MainView,
    meta: { requiresAuth: true },
  },
  {
    path: "/hotels",
    name: "HotelList",
    component: () => import("@/components/HotelListComponent.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/hotels/:hotel_id",
    name: "HotelDetail",
    component: () => import("@/components/HotelDetailComponent.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    name: "UserList",
    component: () => import("@/components/UserListComponent.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/users/:user_id",
    name: "UserDetail",
    component: () => import("@/components/UserDetailComponent.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/snapshots",
    name: "SnapshotList",
    component: () => import("@/components/SnapshotListComponent.vue"),
    meta: { requiresAuth: true },
  },
  {
    path:"/slots",
    name:"SlotList",
    component:() => import("@/components/SlotListComponent.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/templates",
    name: "TemplateCategories",
    component: () => import("@/components/TemplateCategories.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/templates/:templateCategory",
    name: "Templates",
    component: () => import("@/components/Templates.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/menu-templates/:templateCategory",
    name: "MenuTemplatesCategory",
    component: () => import("@/components/MenuTemplatesCategory.vue"),
    meta: { requiresAuth: true },
  },
  {
    path:"/label-templates",
    name:"LiteLabelTemplates",
    component:() => import("@/components/LiteLabelTemplates.vue"),
    meta: {requiresAuth: true}
  },
  {
    path: "/menu-templates",
    name: "MenuTemplates",
    component: () => import("@/components/MenuTemplates.vue"),
    meta: { requiresAuth: true },
  },
  {
    path:"/label-categories",
    name:"LabelCategories",
    component: () => import("@/components/LabelCategories.vue"),
    meta: { requiresAuth: true }
  },
  {
    path:"/label-categories/:category_id",
    name:"LabelCategory",
    component: () => import("@/components/LabelCategory.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: () => import("@/components/Statistics.vue"),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token")) {
      store.commit("adminModule/setAuth", true);
    }
    if (!store.getters["adminModule/getAuth"]) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
