import templateModuleState from "@/store/modules/templateModule/state";
import templateModuleGetters from "@/store/modules/templateModule/getters";
import templateModuleActions from "@/store/modules/templateModule/actions";
import templateModuleMutations from "@/store/modules/templateModule/mutations";
import { Module } from "vuex-smart-module";
export const templateModule = new Module({
  state: templateModuleState,
  getters: templateModuleGetters,
  mutations: templateModuleMutations,
  actions: templateModuleActions,
});
