import $api from "@/http";
import { AxiosResponse } from "axios";
import { ISnapshot } from "@/models/ISnapshot";

export default class SnapshotService {
  static getSnapshotList(): Promise<AxiosResponse<ISnapshot[]>> {
    return $api.get("/backups");
  }
  static updateSnapshot(
    snapshot: ISnapshot
  ): Promise<AxiosResponse<ISnapshot>> {
    return $api.put(`/update-backup/${snapshot.id}`, snapshot);
  }
  static deleteSnapshot(id: number): Promise<AxiosResponse<string>> {
    return $api.delete(`/backup/${id}`);
  }
}
