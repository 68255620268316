import { Mutations } from "vuex-smart-module";
import userModuleState from "@/store/modules/userModule/state";
import { IUser } from "@/models/IUser";

export default class userModuleMutations extends Mutations<userModuleState> {
  setLoading(bool: boolean) {
    this.state.isLoading = bool;
  }
  setUser(user: IUser) {
    this.state.user = user;
  }
  setUserList(userList: IUser[]) {
    this.state.userList = userList;
  }
  setDeleteUser(id: string) {
    const candidate = this.state.userList.find((x) => x.id === +id);
    if (candidate) {
      this.state.userList.splice(this.state.userList.indexOf(candidate), 1);
    }
  }
  setBlockUser(id: string) {
    const candidate = this.state.userList.find((x) => x.id === +id);
    if (candidate) {
      if (candidate.is_blocked) {
        candidate.is_blocked = 0;
      } else {
        candidate.is_blocked = 1;
      }
    }
  }
  setAgencyUser(user:IUser){
      const candidate = this.state.userList.find((x) => x.id === user.id);
      if (candidate) {
          if (candidate.is_agency) {
              candidate.is_agency = 0;
          } else {
              candidate.is_agency = 1;
          }
      }
  }
  setManagerUser(id: string) {
    const candidate = this.state.userList.find((x) => x.id === +id);
    if (candidate) {
      if (candidate.is_manager) {
        candidate.is_manager = 0;
      } else {
        candidate.is_manager = 1;
      }
    }
  }
  setReceptionUser(id: string) {
    const candidate = this.state.userList.find((x) => x.id === +id);
    if (candidate) {
      if (candidate.is_reception) {
        candidate.is_reception = 0;
      } else {
        candidate.is_reception = 1;
      }
    }
  }
  setVerifyUser(id: string) {
    const candidate = this.state.userList.find((x) => x.id === +id);
    if (candidate) {
      candidate.status = 10;
    }
  }
  setStatistics(statistics: []) {
    this.state.statistics = statistics;
  }
}
