import $api from "@/http";
import { AxiosResponse } from "axios";
import {IMenuTemplate} from "@/models/IMenuTemplate";
import {IMenuTemplateCategory} from "@/models/IMenuTemplateCategory"

const categoryEndPoint = '/menu-template-category';
const templateEndPoint = '/menu-template'

export class MenuTemplateCategoryService{
  static getCategoriesList():Promise<AxiosResponse<IMenuTemplateCategory[]>>{
    return $api.get<IMenuTemplateCategory[]>(`/menu-template-categories`)
  }
  static getCategory(id:number):Promise<AxiosResponse<IMenuTemplateCategory>>{
    return $api.get<IMenuTemplateCategory>(`${categoryEndPoint}/${id}`)
  }
  static postCategory(category:IMenuTemplateCategory):Promise<AxiosResponse<IMenuTemplateCategory>>{
    return $api.post<IMenuTemplateCategory>(categoryEndPoint,category)
  }
  static putCategory(category:IMenuTemplateCategory):Promise<AxiosResponse<IMenuTemplateCategory>>{
    return $api.put<IMenuTemplateCategory>(`${categoryEndPoint}/${category.id}`,category)
  }
  static deleteCategory(id:number):Promise<AxiosResponse<IMenuTemplateCategory>>{
    return $api.delete<IMenuTemplateCategory>(`${categoryEndPoint}/${id}`);
  }
}

export class MenuTemplateService{
  static getTemplatesByCategory(id:number):Promise<AxiosResponse<IMenuTemplate[]>>{
    return $api.get<IMenuTemplate[]>(`/menu-templates/${id}`)
  }
  static getTemplate(id:number):Promise<AxiosResponse<IMenuTemplate>>{
    return $api.get<IMenuTemplate>(`${templateEndPoint}/${id}`)
  }
  static postTemplate(template:IMenuTemplate):Promise<AxiosResponse<IMenuTemplate>>{
    return $api.post<IMenuTemplate>(templateEndPoint,template)
  }
  static putTemplate(template:IMenuTemplate):Promise<AxiosResponse<IMenuTemplate>>{
    return $api.put<IMenuTemplate>(`${templateEndPoint}/${template.id}`,template)
  }
  static deleteTemplate(id:number):Promise<AxiosResponse<IMenuTemplate>>{
    return $api.delete<IMenuTemplate>(`${templateEndPoint}/${id}`)
  }
}
