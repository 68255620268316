import $api from "@/http";
import { AxiosResponse } from "axios";
import { ITemplate } from "@/models/ITemplate";
import { ITemplateCategory } from "@/models/ITemplateCategory";

export default class TemplateService {
  static getTemplateCategoriesList(): Promise<
    AxiosResponse<ITemplateCategory[]>
  > {
    return $api.get(`/template-categories`);
  }
  static getTemplateCategory(
    id: number
  ): Promise<AxiosResponse<ITemplateCategory>> {
    return $api.get(`/template-category/${id}`);
  }
  static postTemplateCategory(
    templateCategory: ITemplateCategory
  ): Promise<AxiosResponse<ITemplateCategory>> {
    return $api.post(`/template-category`, templateCategory);
  }
  static putTemplateCategory(
    templateCategory: ITemplateCategory
  ): Promise<AxiosResponse<ITemplateCategory>> {
    return $api.put(
      `/template-category/${templateCategory.id}`,
      templateCategory
    );
  }
  static deleteTemplateCategory(
    id: number
  ): Promise<AxiosResponse<ITemplateCategory>> {
    return $api.delete(`/template-category/${id}`);
  }

  static getTemplateList(): Promise<AxiosResponse<ITemplate[]>> {
    return $api.get(`/templates`);
  }
  static getTemplate(id: number): Promise<AxiosResponse<ITemplate>> {
    return $api.get(`/template/${id}`);
  }
  static postTemplate(template: ITemplate): Promise<AxiosResponse<ITemplate>> {
    return $api.post(`/template`, template);
  }
  static putTemplate(template: ITemplate): Promise<AxiosResponse<ITemplate>> {
    return $api.put(`/template/${template.id}`, template);
  }
  static deleteTemplate(id: number): Promise<AxiosResponse<ITemplate>> {
    return $api.delete(`/template/${id}`);
  }
  static postTemplateByHotelId(template:ITemplate):Promise<AxiosResponse<ITemplate>>{
    return $api.post(`/template-by-hotel-id`,template)
  }
}
