import { Getters } from "vuex-smart-module";
import userModuleState from "@/store/modules/userModule/state";

export default class userModuleGetters extends Getters<userModuleState> {
  get getUser() {
    return this.state.user;
  }
  get getUserListData() {
    return this.state.userList;
  }
  get getLoadingUser() {
    return this.state.isLoading;
  }
  get getUserStatistics() {
    return this.state.statistics;
  }
}
