import { Actions } from "vuex-smart-module";
import menuTemplateModuleState from "@/store/modules/menuTemplateModule/state";
import menuTemplateModuleGetters from "@/store/modules/menuTemplateModule/getters";
import menuTemplateModuleMutations from "@/store/modules/menuTemplateModule/mutations";
import {MenuTemplateCategoryService, MenuTemplateService} from "@/services/MenuTemplateService";
import Vue from "vue";
import {IMenuTemplateCategory} from "@/models/IMenuTemplateCategory";
import {IMenuTemplate} from "@/models/IMenuTemplate";

export default class menuTemplateModuleActions extends Actions<
  menuTemplateModuleState,
  menuTemplateModuleGetters,
  menuTemplateModuleMutations,
  menuTemplateModuleActions
> {
  async getTemplateCategoriesList() {
    try {
      this.commit("setLoading", true);
      const { data } = await MenuTemplateCategoryService.getCategoriesList()
      this.commit("setTemplateCategories", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async postTemplateCategory(category:IMenuTemplateCategory) {
    try {
      this.commit("setLoading", true);
      const { data } = await MenuTemplateCategoryService.postCategory(category)
      this.commit("addTemplateCategory", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async putTemplateCategory(category: IMenuTemplateCategory) {
    try {
      this.commit("setLoading", true);
      const { data } = await MenuTemplateCategoryService.putCategory(
        category
      );
      this.commit("putTemplateCategory", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async deleteTemplateCategory(id: number) {
    try {
      this.commit("setLoading", true);
      const { data } = await MenuTemplateCategoryService.deleteCategory(id);
      this.commit("deleteTemplateCategory", data);
    }
    catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    }
    finally {
      this.commit("setLoading", false);
    }
  }
  async getTemplatesByCategoryID(id:number){
    try {
      this.commit("setLoading", true);
      const { data } = await MenuTemplateService.getTemplatesByCategory(id);
      this.commit("setTemplates", data);
    }
    catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    }
    finally {
      this.commit("setLoading", false);
    }
  }
  async postTemplate(template:IMenuTemplate){
    try {
      this.commit("setLoading", true);
      const newContent = {
        content:JSON.parse(template.content)
      }
      const sendData = JSON.parse(JSON.stringify(template));
      sendData.content = newContent;
      const { data } = await MenuTemplateService.postTemplate(sendData);
      this.commit("addTemplate", data);
    }
    catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    }
    finally {
      this.commit("setLoading", false);
    }
  }
  async putTemplate(template:IMenuTemplate){
    try {
      this.commit("setLoading", true);
      const sendData = JSON.parse(JSON.stringify(template));
      sendData.content = JSON.parse(sendData.content);
      const { data } = await MenuTemplateService.putTemplate(sendData);
      this.commit("editTemplate", data);
    }
    catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    }
    finally {
      this.commit("setLoading", false);
    }
  }
  async deleteTemplate(id:number){
    try {
      this.commit("setLoading", true);
      const { data } = await MenuTemplateService.deleteTemplate(id);
      this.commit("deleteTemplate", data);
    }
    catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    }
    finally {
      this.commit("setLoading", false);
    }
  }
}
