import { ITemplateCategory } from "@/models/ITemplateCategory";
import { ITemplate } from "@/models/ITemplate";

export default class templateModuleState {
  loading = false;
  templateCategories: ITemplateCategory[] = [] as ITemplateCategory[];
  templateCategory: ITemplateCategory | null = null;
  templates: ITemplate[] = [] as ITemplate[];
  template: ITemplate | null = null;
}
