import $api from "@/http";
import { AuthResponse } from "@/models/responce/AuthResponce";
import { AxiosResponse } from "axios";

export default class AuthService {
  static async login(
    username: string,
    password: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>("/login", { username, password });
  }
}
