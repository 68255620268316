import { Actions } from "vuex-smart-module";
import snapshotModuleState from "@/store/modules/snapshotModule/state";
import snapshotModuleGetters from "@/store/modules/snapshotModule/getters";
import snapshotModuleMutations from "@/store/modules/snapshotModule/mutations";
import SnapshotService from "@/services/SnapshotService";
import Vue from "vue";
import { ISnapshot } from "@/models/ISnapshot";

export default class snapshotModuleActions extends Actions<
  snapshotModuleState,
  snapshotModuleGetters,
  snapshotModuleMutations,
  snapshotModuleActions
> {
  async getSnapshotList() {
    try {
      this.commit("setLoading", true);
      const { data } = await SnapshotService.getSnapshotList();
      this.commit("setSnapshotList", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async deleteSnapshot(id: number) {
    try {
      this.commit("setLoading", true);
      await SnapshotService.deleteSnapshot(id);
      this.commit("deleteSnapshot", id);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async updateSnapshot(item: ISnapshot) {
    try {
      this.commit("setLoading", true);
      const { data } = await SnapshotService.updateSnapshot(item);
      this.commit("updateSnapshot", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
}
