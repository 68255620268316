import { Mutations } from "vuex-smart-module";
import menuTemplateModuleState from "@/store/modules/menuTemplateModule/state";
import {IMenuTemplateCategory} from "@/models/IMenuTemplateCategory";
import {IMenuTemplate} from "@/models/IMenuTemplate";

export default class menuTemplateModuleMutations extends Mutations<menuTemplateModuleState> {
  setLoading(loading: boolean) {
    this.state.loading = loading;
  }
  setTemplateCategories(categories:IMenuTemplateCategory[]){
    this.state.categories = categories;
  }
  addTemplateCategory(category:IMenuTemplateCategory){
    this.state.categories.push(category)
  }
  putTemplateCategory(category:IMenuTemplateCategory){
    const candidate = this.state.categories.find(
      (item) => item.id === category.id
    );
    if (candidate) {
      const candidateIndex = this.state.categories.indexOf(candidate);
      if (candidateIndex >= 0) {
        this.state.categories.splice(
          candidateIndex,
          1,
          category
        );
      }
    }
  }
  deleteTemplateCategory(category:IMenuTemplateCategory){
    const candidate = this.state.categories.find(
      (item) => item.id === category.id
    );
    if (candidate) {
      const candidateIndex = this.state.categories.indexOf(candidate);
      if (candidateIndex >= 0) {
        this.state.categories.splice(
          candidateIndex,
          1
        );
      }
    }
  }
  setTemplateCategory(category:IMenuTemplate){
    this.state.category = category;
  }
  setTemplates(templates:IMenuTemplate[]){
    this.state.templates = templates;
  }
  setTemplate(template:IMenuTemplate){
    this.state.template = template
  }
  addTemplate(template:IMenuTemplate){
    this.state.templates.push(template);
  }
  editTemplate(template:IMenuTemplate){
    const candidate = this.state.templates.find(item => item.id === template.id);
    if(candidate){
      const candidateIndex = this.state.templates.indexOf(candidate);
      if(candidateIndex>=0){
        this.state.templates.splice(
          candidateIndex,
          1,
          template
        )
      }
    }
  }
  deleteTemplate(template:IMenuTemplate){
    const candidate = this.state.templates.find(item => item.id === template.id);
    if(candidate){
      const candidateIndex = this.state.templates.indexOf(candidate);
      if(candidateIndex>=0){
        this.state.templates.splice(
          candidateIndex,
          1
        )
      }
    }
  }
}
