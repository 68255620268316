import Vue from "vue";
import * as Vuex from "vuex";
import { createStore, Module } from "vuex-smart-module";
import { adminModule } from "@/store/modules/adminModule";
import { hotelModule } from "@/store/modules/hotelModule";
import { userModule } from "@/store/modules/userModule";
import { snapshotModule } from "@/store/modules/snapshotModule";
import { templateModule } from "@/store/modules/templateModule";
import {slotModule} from "@/store/modules/slotModule";
import {menuTemplateModule} from "@/store/modules/menuTemplateModule";
import {labelModule} from "@/store/modules/labelModule";
import {liteLabelModule} from "@/store/modules/liteLabelModule"

Vue.use(Vuex);
const root = new Module({
  modules: {
    adminModule,
    hotelModule,
    userModule,
    snapshotModule,
    templateModule,
    slotModule,
    menuTemplateModule,
    labelModule,
    liteLabelModule
  },
});
export default createStore(root, {
  strict: false,
});
