import $api from "@/http";
import { AxiosResponse } from "axios";
import { IUser } from "@/models/IUser";

export default class UserService {
  static getUserList(): Promise<AxiosResponse<IUser[]>> {
    return $api.get("/list/User");
  }
  static deleteUser(id: string): Promise<AxiosResponse<IUser>> {
    return $api.delete(`/delete/User/${id}`);
  }
  static loginAsUser(id: string): Promise<AxiosResponse> {
    return $api.get(`/login-as-user/${id}`);
  }
  static editUser(payload: {
    id: string;
    user: IUser;
  }): Promise<AxiosResponse<IUser>> {
    return $api.patch(`/update/User/${payload.id}`, payload.user);
  }
  static verifyUser(id: string): Promise<AxiosResponse<IUser>> {
    return $api.get(`/verify-from-admin/${id}`);
  }
  static getStatisticsUsers(): Promise<AxiosResponse> {
    return $api.get(`/get-chart-by-day/users`);
  }
  static toggleBlockUser(id: string): Promise<AxiosResponse> {
    return $api.get(`/toggle-to-block/${id}`);
  }
  static toggleManagerUser(id: string): Promise<AxiosResponse> {
    return $api.get(`/toggle-to-manager/${id}`);
  }
  static toggleReceptionkUser(id: string): Promise<AxiosResponse> {
    return $api.get(`/toggle-to-reception/${id}`);
  }
  static toggleAgencyUser(user:IUser):Promise<AxiosResponse<IUser>>{
      return $api.put(`/toggle-agency/${user.id}`,user);
  }
}
