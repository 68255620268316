import userModuleState from "@/store/modules/userModule/state";
import userModuleGetters from "@/store/modules/userModule/getters";
import userModuleMutations from "@/store/modules/userModule/mutations";
import userModuleActions from "@/store/modules/userModule/actions";
import { Module } from "vuex-smart-module";
export const userModule = new Module({
  state: userModuleState,
  getters: userModuleGetters,
  mutations: userModuleMutations,
  actions: userModuleActions,
});
