import { Mutations } from "vuex-smart-module";
import templateModuleState from "@/store/modules/templateModule/state";
import { ITemplateCategory } from "@/models/ITemplateCategory";
import { ITemplate } from "@/models/ITemplate";

export default class templateModuleMutations extends Mutations<templateModuleState> {
  setLoading(loading: boolean) {
    this.state.loading = loading;
  }

  setTemplateCategories(list: ITemplateCategory[]) {
    this.state.templateCategories = list;
  }
  setTemplateCategory(templateCategory: ITemplateCategory) {
    this.state.templateCategory = templateCategory;
  }
  postTemplateCategory(templateCategory: ITemplateCategory) {
    this.state.templateCategories.push(templateCategory);
  }
  putTemplateCategory(templateCategory: ITemplateCategory) {
    const candidate = this.state.templateCategories.find(
      (category) => category.id === templateCategory.id
    );
    if (candidate) {
      const candidateIndex = this.state.templateCategories.indexOf(candidate);
      if (candidateIndex >= 0) {
        this.state.templateCategories.splice(
          candidateIndex,
          1,
          templateCategory
        );
      }
    }
  }
  deleteTemplateCategory(templateCategory: ITemplateCategory) {
    const candidate = this.state.templateCategories.find(
      (category) => (category.id = templateCategory.id)
    );
    if (candidate) {
      const candidateIndex = this.state.templateCategories.indexOf(candidate);
      if (candidateIndex >= 0) {
        this.state.templateCategories.splice(candidateIndex, 1);
      }
    }
  }

  setTemplates(list: ITemplate[]) {
    this.state.templates = list;
  }
  setTemplate(template: ITemplate) {
    this.state.template = template;
  }
  postTemplate(template: ITemplate) {
    this.state.templates.push(template);
  }
  putTemplate(template: ITemplate) {
    const candidate = this.state.templates.find(
      (item) => item.id === template.id
    );
    if (candidate) {
      const candidateIndex = this.state.templates.indexOf(candidate);
      if (candidateIndex >= 0) {
        this.state.templates.splice(candidateIndex, 1, template);
      }
    }
  }
  deleteTemplate(template: ITemplate) {
    const candidate = this.state.templates.find(
      (item) => item.id === template.id
    );
    if (candidate) {
      const candidateIndex = this.state.templates.indexOf(candidate);
      if (candidateIndex >= 0) {
        this.state.templates.splice(candidateIndex, 1);
      }
    }
  }
}
