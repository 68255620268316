


















































import EmailOutline from "vue-material-design-icons/EmailOutline.vue";
import Lock from "vue-material-design-icons/Lock.vue";
import { mapActions } from "vuex";
export default {
  name: "loginView",
  components: {
    EmailOutline,
    Lock,
  },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    ...mapActions("adminModule", ["login"]),
  },
};
