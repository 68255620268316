import { Actions } from "vuex-smart-module";
import adminModuleState from "@/store/modules/adminModule/state";
import adminModuleGetters from "@/store/modules/adminModule/getters";
import adminModuleMutations from "@/store/modules/adminModule/mutations";
import AuthService from "@/services/AuthService";
import { IAdmin } from "@/models/IAdmin";
import router from "@/router";
import Vue from "vue";
export default class adminModuleActions extends Actions<
  adminModuleState,
  adminModuleGetters,
  adminModuleMutations,
  adminModuleActions
> {
  async login(payload: { username: string; password: string }) {
    try {
      this.commit("setLoading", true);
      if (!payload.username) {
        throw new SyntaxError("Email is required!");
      }
      if (!payload.password) {
        throw new SyntaxError("Password is required!");
      }
      const response = await AuthService.login(
        payload.username,
        payload.password
      );
      localStorage.setItem("token", response.data.token);
      this.commit("setAdmin", response.data.admin);
      this.commit("setAuth", true);
      await router.push("/");
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async logout() {
    try {
      this.commit("setLoading", true);
      localStorage.removeItem("token");
      this.commit("setAuth", false);
      this.commit("setAdmin", {} as IAdmin);
      await router.push("/login");
    } catch (e) {
      console.log(e.response?.data?.message);
    } finally {
      this.commit("setLoading", false);
    }
  }
}
