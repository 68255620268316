<template>
  <div
    class="loading"
    v-if="getLoadingAdmin || getLoadingUser || getLoadingHotel || loading"
  >
    <img src="../assets/logo.svg" alt="loading" class="loading__icon" />
  </div>
</template>
<style lang="scss">
.loading {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 888;
  left: 0;
  top: 0;
  &__icon {
    animation: 1s linear 0s normal none infinite running circle;
  }
  @keyframes circle {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import {slotModule} from "@/store/modules/slotModule";
export default {
  name: "loading",
  computed: {
    ...mapGetters("adminModule", ["getLoadingAdmin"]),
    ...mapGetters("hotelModule", ["getLoadingHotel"]),
    ...mapGetters("userModule", ["getLoadingUser"]),
    ...slotModule.mapState(['loading']),
  },
};
</script>
