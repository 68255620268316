import {Module} from "vuex-smart-module";
import liteLabelModuleState from "@/store/modules/liteLabelModule/state";
import liteLabelModuleGetters from "@/store/modules/liteLabelModule/getters";
import liteLabelModuleActions from "@/store/modules/liteLabelModule/actions";
import liteLabelModuleMutations from "@/store/modules/liteLabelModule/mutations";

export const liteLabelModule = new Module({
  state:liteLabelModuleState,
  getters:liteLabelModuleGetters,
  actions:liteLabelModuleActions,
  mutations:liteLabelModuleMutations
})
