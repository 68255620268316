import { Getters } from "vuex-smart-module";
import adminModuleState from "@/store/modules/adminModule/state";

export default class adminModuleGetters extends Getters<adminModuleState> {
  get getAuth() {
    return this.state.isAuth;
  }
  get getAdmin() {
    return this.state.admin;
  }
  get getLoadingAdmin() {
    return this.state.isLoading;
  }
}
