import { Mutations } from "vuex-smart-module";
import snapshotModuleState from "@/store/modules/snapshotModule/state";
import { ISnapshot } from "@/models/ISnapshot";
export default class snapshotModuleMutations extends Mutations<snapshotModuleState> {
  setLoading(bool: boolean) {
    this.state.isLoading = bool;
  }
  setSnapshotList(snapshots: ISnapshot[]) {
    this.state.snapshotList = snapshots;
  }
  deleteSnapshot(id: number) {
    const candidate = this.state.snapshotList.find((x) => x.id === id);
    if (candidate) {
      this.state.snapshotList.splice(
        this.state.snapshotList.indexOf(candidate),
        1
      );
    }
  }
  updateSnapshot(snapshot: ISnapshot) {
    const candidate = this.state.snapshotList.find((x) => x.id === snapshot.id);
    if (candidate) {
      this.state.snapshotList.splice(
        this.state.snapshotList.indexOf(candidate),
        1,
        snapshot
      );
    }
  }
}
