import adminModuleState from "@/store/modules/adminModule/state";
import adminModuleGetters from "@/store/modules/adminModule/getters";
import adminModuleMutations from "@/store/modules/adminModule/mutations";
import adminModuleActions from "@/store/modules/adminModule/actions";
import { Module } from "vuex-smart-module";
export const adminModule = new Module({
  state: adminModuleState,
  getters: adminModuleGetters,
  mutations: adminModuleMutations,
  actions: adminModuleActions,
});
