import { Actions } from "vuex-smart-module";
import hotelModuleState from "@/store/modules/hotelModule/state";
import hotelModuleGetters from "@/store/modules/hotelModule/getters";
import hotelModuleMutations from "@/store/modules/hotelModule/mutations";
import Vue from "vue";
import HotelService from "@/services/HotelService";
import { IHotel } from "@/models/IHotel";
import moment from "moment";
export default class hotelModuleActions extends Actions<
  hotelModuleState,
  hotelModuleGetters,
  hotelModuleMutations,
  hotelModuleActions
> {
  async getHotelList() {
    try {
      this.commit("setLoading", true);
      const response = await HotelService.getHotelList();
      if (response.status === 201) {
        this.commit("setHotelList", response.data);
      } else {
        throw new SyntaxError("Password is required!");
      }
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async editHotel(payload: { id: number; hotel: IHotel }) {
    try {
      this.commit("setLoading", true);
      await HotelService.editHotel(payload);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async deleteHotel(id: number) {
    try {
      this.commit("setLoading", true);
      await HotelService.deleteHotel(id);
      this.commit("setDeleteHotel", id);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async getUploadJson(id: number) {
    try {
      this.commit("setLoading", true);
      const response = await HotelService.getHotelJson(id);
      const candidate = this.state.hotelList.find((x) => x.id === id);
      const { data } = response;
      if (candidate) {
        const blob = new Blob([JSON.stringify(data)], { type: "text/json" });
        const link = document.createElement("a");
        link.download =
          candidate.name.replaceAll(" ", "_") +
          "_" +
          moment().format("DD_MM_yyyy_HH_mm") +
          ".json";
        link.href = window.URL.createObjectURL(blob);
        link.dataset.downloadurl = ["text/json", link.download, link.href].join(
          ":"
        );
        const evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        link.dispatchEvent(evt);
        link.remove();
      }
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async setUploadJson(payload: { hotel_id: number; file: File }) {
    try {
      this.commit("setLoading", true);
      await HotelService.uploadHotelJson(payload);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async getHotelStatistics() {
    try {
      this.commit("setLoading", true);
      const response = await HotelService.getHotelStatistics();
      this.commit("setStatistics", response.data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async changeUser(payload:{
      hotel_id:number;
      user_id:number;
  }){
      try{
          this.commit("setLoading", true);
          const response = await HotelService.changeUser(payload);
          console.log(response);
      }catch (e) {
          if (e.name == "SyntaxError") {
              Vue.notify({
                  group: "alert",
                  title: "Ошибка",
                  text: e.message,
              });
          } else {
              Vue.notify({
                  group: "alert",
                  title: "Ошибка",
                  text: e.response?.data?.message,
              });
          }
      }finally {
          this.commit("setLoading", false);
      }
  }
  async getDownloadZipImages(id: number) {
    try {
      this.commit("setLoading", true);
      const response = await HotelService.getZipImages(id);
      console.log(response);
      const candidate = this.state.hotelList.find((x) => x.id === id);
      const { data } = response;
      if (candidate) {
        const link = document.createElement("a");
        link.href = data;
        const evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        link.dispatchEvent(evt);
        link.remove();
      }
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
}
