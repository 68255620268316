import { Module } from "vuex-smart-module";
import labelModuleState from "@/store/modules/labelModule/state";
import labelModuleGetters from "@/store/modules/labelModule/getters";
import labelModuleMutations from "@/store/modules/labelModule/mutations";
import labelModuleActions from "@/store/modules/labelModule/actions";
export const labelModule = new Module({
  state: labelModuleState,
  getters: labelModuleGetters,
  mutations: labelModuleMutations,
  actions: labelModuleActions,
});
