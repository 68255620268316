import hotelModuleState from "@/store/modules/hotelModule/state";
import hotelModuleGetters from "@/store/modules/hotelModule/getters";
import hotelModuleMutations from "@/store/modules/hotelModule/mutations";
import hotelModuleActions from "@/store/modules/hotelModule/actions";
import { Module } from "vuex-smart-module";
export const hotelModule = new Module({
  state: hotelModuleState,
  getters: hotelModuleGetters,
  mutations: hotelModuleMutations,
  actions: hotelModuleActions,
});
