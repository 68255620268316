






import MainDashboard from "../components/MainDashboardComponent.vue";
export default {
  name: "MainView",
  components: {
    MainDashboard,
  },
  data() {
    return {};
  },
};
