import {ILabel} from "@/models/ILabel";
import {ILabelCategory} from "@/models/ILabelCategory";

export default class labelModuleState {
  loading = false;
  categories:ILabelCategory[] = [];
  category:ILabelCategory = {} as ILabelCategory;
  labels:ILabel[] = [];
  label:ILabel = {} as ILabel;
}
