import { Actions } from "vuex-smart-module";
import templateModuleState from "@/store/modules/templateModule/state";
import templateModuleGetters from "@/store/modules/templateModule/getters";
import templateModuleMutations from "@/store/modules/templateModule/mutations";
import Vue from "vue";
import TemplateService from "@/services/TemplateService";
import { ITemplate } from "@/models/ITemplate";
import { ITemplateCategory } from "@/models/ITemplateCategory";

export default class templateModuleActions extends Actions<
  templateModuleState,
  templateModuleGetters,
  templateModuleMutations,
  templateModuleActions
> {
  async getTemplateCategoriesList() {
    try {
      this.commit("setLoading", true);
      const { data } = await TemplateService.getTemplateCategoriesList();
      this.commit("setTemplateCategories", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async getTemplateCategory(id: number) {
    try {
      this.commit("setLoading", true);
      const { data } = await TemplateService.getTemplateCategory(id);
      this.commit("setTemplateCategory", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async postTemplateCategory(templateCategory: ITemplateCategory) {
    try {
      this.commit("setLoading", true);
      const { data } = await TemplateService.postTemplateCategory(
        templateCategory
      );
      this.commit("postTemplateCategory", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async putTemplateCategory(templateCategory: ITemplateCategory) {
    try {
      this.commit("setLoading", true);
      const { data } = await TemplateService.putTemplateCategory(
        templateCategory
      );
      this.commit("putTemplateCategory", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async deleteTemplateCategory(id: number) {
    try {
      this.commit("setLoading", true);
      const { data } = await TemplateService.deleteTemplateCategory(id);
      this.commit("deleteTemplateCategory", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }

  async getTemplateList() {
    try {
      this.commit("setLoading", true);
      const { data } = await TemplateService.getTemplateList();
      this.commit("setTemplates", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async getTemplate(id: number) {
    try {
      this.commit("setLoading", true);
      const { data } = await TemplateService.getTemplate(id);
      this.commit("setTemplate", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async postTemplate(template: ITemplate) {
    try {
      this.commit("setLoading", true);
      const sendData = JSON.parse(JSON.stringify(template));
      console.log(sendData)
      sendData.content = JSON.parse(sendData.content);
      const { data } = await TemplateService.postTemplate(sendData);
      this.commit("postTemplate", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async postTemplateByHotelId(template: ITemplate) {
    try {
      this.commit("setLoading", true);
      const { data } = await TemplateService.postTemplateByHotelId(template);
      this.commit("postTemplate", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async putTemplate(template: ITemplate) {
    try {
      this.commit("setLoading", true);
      const sendData = JSON.parse(JSON.stringify(template));
      sendData.content = JSON.parse(sendData.content);
      const { data } = await TemplateService.putTemplate(template);
      this.commit("putTemplate", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async deleteTemplate(id: number) {
    try {
      this.commit("setLoading", true);
      const { data } = await TemplateService.deleteTemplate(id);
      this.commit("deleteTemplate", data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
}
