import { Mutations } from "vuex-smart-module";
import labelModuleState from "@/store/modules/labelModule/state";
import {ILabelCategory} from "@/models/ILabelCategory";

export default class labelModuleMutations extends Mutations<labelModuleState> {
  setLoading(loading: boolean) {
    this.state.loading = loading;
  }
  setCategories(categories:ILabelCategory[]){
    this.state.categories = categories
  }
  setCategory(category:ILabelCategory){
    this.state.category = category
  }
  postCategory(category:ILabelCategory){
    this.state.categories.push(category);
  }
  putCategory(category:ILabelCategory){
    const candidate = this.state.categories.find(item => item.id === category.id);
    if(candidate){
      const candidateIndex = this.state.categories.indexOf(candidate);
      if(candidateIndex>=0){
        this.state.categories.splice(candidateIndex,1,category)
      }
    }
  }
  deleteCategory(category:ILabelCategory){
    const candidate = this.state.categories.find(item => item.id === category.id);
    if(candidate){
      const candidateIndex = this.state.categories.indexOf(candidate);
      if(candidateIndex>=0){
        this.state.categories.splice(candidateIndex,1)
      }
    }
  }
}
