import {Actions} from "vuex-smart-module";
import liteLabelModuleState from "@/store/modules/liteLabelModule/state";
import liteLabelModuleGetters from "@/store/modules/liteLabelModule/getters";
import liteLabelModuleMutations from "@/store/modules/liteLabelModule/mutations";
import Vue from "vue";
import {LiteLabelService} from "@/services/LiteLabelService";
import {ILiteLabel} from "@/models/ILiteLabel";


export default class liteLabelModuleActions extends Actions<
  liteLabelModuleState,
  liteLabelModuleGetters,
  liteLabelModuleMutations,
  liteLabelModuleActions
> {
  async getLiteLabels(){
    try{
      this.commit('setLoading',true)
      const {data} = await LiteLabelService.getLiteLables();
      this.commit('setLiteLabels',data);
    }catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async getLiteLabel(id:number){
    try{
      this.commit('setLoading',true);
      const {data} = await LiteLabelService.getLiteLabel(id);
      this.commit('setLiteLabel',data)
    }catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async postLiteLabel(label:ILiteLabel){
    try{
     this.commit('setLoading',true);
     const {data}  = await LiteLabelService.postLiteLabel(label);
     this.commit('addLiteLabel',data)
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async putLiteLabel(label:ILiteLabel){
    try{
      this.commit('setLoading',true);
      const {data}  = await LiteLabelService.editLiteLabel(label);
      this.commit('editLiteLabel',data)
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async deleteLiteLabel(id:number){
    try{
      this.commit('setLoading',true);
      const {data}  = await LiteLabelService.deleteLiteLabel(id);
      this.commit('deleteLiteLabel',data)
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
}
