import $api from "@/http";
import { AxiosResponse } from "axios";
import {ISlot} from "@/models/ISlot";


export default class SlotService {
    static getSlots():Promise<AxiosResponse<ISlot[]>> {
        return $api.get(`/slots`);
    }
    static getSlot(slot_id:number):Promise<AxiosResponse<ISlot>> {
        return $api.get(`/slot/${slot_id}`);
    }
    static postSlot(slot:ISlot):Promise<AxiosResponse<ISlot>>{
        return $api.post(`/slot`,slot);
    }
    static putSlot(slot:ISlot):Promise<AxiosResponse<ISlot>>{
        return $api.put(`/slot/${slot.id}`,slot);
    }
    static deleteSlot(slot_id:number):Promise<AxiosResponse<ISlot>>{
        return $api.delete(`/slot/${slot_id}`);
    }
}
