import { Actions } from "vuex-smart-module";
import slotModuleState from "@/store/modules/slotModule/state";
import slotModuleGetters from "@/store/modules/slotModule/getters";
import slotModuleMutations from "@/store/modules/slotModule/mutations";
import SlotService from "@/services/SlotService";
import Vue from "vue";
import {ISlot} from "@/models/ISlot";


export default class slotModuleActions extends Actions<
    slotModuleState,
    slotModuleGetters,
    slotModuleMutations,
    slotModuleActions
    > {
    async getSlots(){
        try{
            this.commit("setLoading", true);
            const {data} = await SlotService.getSlots();
            this.commit('setSlots',data);
        } catch (e) {
            if (e.name == "SyntaxError") {
                Vue.notify({
                    group: "alert",
                    title: "Ошибка",
                    text: e.message,
                });
            } else {
                Vue.notify({
                    group: "alert",
                    title: "Ошибка",
                    text: e.response?.data?.message,
                });
            }
        } finally {
            this.commit("setLoading", false);
        }
    }
    async getSlot(slot_id:number){
        try{
            this.commit("setLoading", true);
            const {data} = await SlotService.getSlot(slot_id);
            this.commit('setSlot',data);
        } catch (e) {
            if (e.name == "SyntaxError") {
                Vue.notify({
                    group: "alert",
                    title: "Ошибка",
                    text: e.message,
                });
            } else {
                Vue.notify({
                    group: "alert",
                    title: "Ошибка",
                    text: e.response?.data?.message,
                });
            }
        } finally {
            this.commit("setLoading", false);
        }
    }
    async postSlot(slot:ISlot){
        try{
            this.commit("setLoading", true);
            const {data} = await SlotService.postSlot(slot);
            this.commit('postSlot',data);
        } catch (e) {
            if (e.name == "SyntaxError") {
                Vue.notify({
                    group: "alert",
                    title: "Ошибка",
                    text: e.message,
                });
            } else {
                Vue.notify({
                    group: "alert",
                    title: "Ошибка",
                    text: e.response?.data?.message,
                });
            }
        } finally {
            this.commit("setLoading", false);
        }
    }
    async putSlot(slot:ISlot){
        try{
            this.commit("setLoading", true);
            const {data} = await SlotService.putSlot(slot);
            this.commit('putSlot',data);
        } catch (e) {
            if (e.name == "SyntaxError") {
                Vue.notify({
                    group: "alert",
                    title: "Ошибка",
                    text: e.message,
                });
            } else {
                Vue.notify({
                    group: "alert",
                    title: "Ошибка",
                    text: e.response?.data?.message,
                });
            }
        } finally {
            this.commit("setLoading", false);
        }
    }
    async deleteSlot(slot_id:number){
        try{
            this.commit("setLoading", true);
            const {data} = await SlotService.deleteSlot(slot_id);
            this.commit('deleteSlot',data);
        } catch (e) {
            if (e.name == "SyntaxError") {
                Vue.notify({
                    group: "alert",
                    title: "Ошибка",
                    text: e.message,
                });
            } else {
                Vue.notify({
                    group: "alert",
                    title: "Ошибка",
                    text: e.response?.data?.message,
                });
            }
        } finally {
            this.commit("setLoading", false);
        }
    }
    }
