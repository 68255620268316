import {IMenuTemplateCategory} from "@/models/IMenuTemplateCategory";
import {IMenuTemplate} from "@/models/IMenuTemplate";


export default class menuTemplateModuleState {
  loading = false;
  categories:IMenuTemplateCategory[] = [];
  category:IMenuTemplateCategory = {} as IMenuTemplateCategory;
  templates:IMenuTemplate[] = [];
  template:IMenuTemplate = {} as IMenuTemplate;
}
