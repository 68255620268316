import { Actions } from "vuex-smart-module";
import userModuleState from "@/store/modules/userModule/state";
import userModuleGetters from "@/store/modules/userModule/getters";
import userModuleMutations from "@/store/modules/userModule/mutations";
import Vue from "vue";
import UserService from "@/services/UserService";
import { IUser } from "@/models/IUser";
import HotelService from "@/services/HotelService";
export default class userModuleActions extends Actions<
  userModuleState,
  userModuleGetters,
  userModuleMutations,
  userModuleActions
> {
  async getUserList() {
    try {
      this.commit("setLoading", true);
      const response = await UserService.getUserList();
      if (response.status === 201) {
        this.commit("setUserList", response.data);
      } else {
        throw new SyntaxError("Password is required!");
      }
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async editUser(payload: { id: string; user: IUser }) {
    try {
      this.commit("setLoading", true);
      await UserService.editUser(payload);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async loginAsUser(id: string) {
    try {
      this.commit("setLoading", false);
      const response = await UserService.loginAsUser(id);
      window.open(
        "https://guesteco.com/?admin_token=" + response.data.admin_token,
        "_blank"
      );
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async verifyUser(id: string) {
    try {
      this.commit("setLoading", true);
      const response = await UserService.verifyUser(id);
      if (response.status === 200) {
        this.commit("setVerifyUser", id);
      }
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async deleteUser(id: string) {
    try {
      this.commit("setLoading", true);
      await UserService.deleteUser(id);
      this.commit("setDeleteUser", id);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async toggleBlockUser(id: string) {
    try {
      this.commit("setLoading", true);
      await UserService.toggleBlockUser(id);
      this.commit("setBlockUser", id);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async toggleAgencyUser(user:IUser){
      try{
          this.commit("setLoading",true);
          await UserService.toggleAgencyUser(user);
          this.commit('setAgencyUser',user);
      }catch (e) {
          if (e.name == "SyntaxError") {
              Vue.notify({
                  group: "alert",
                  title: "Ошибка",
                  text: e.message,
              });
          } else {
              Vue.notify({
                  group: "alert",
                  title: "Ошибка",
                  text: e.response?.data?.message,
              });
          }
      } finally {
          this.commit("setLoading", false);
      }
  }
  async toggleManagerUser(id: string) {
    try {
      this.commit("setLoading", true);
      await UserService.toggleManagerUser(id);
      this.commit("setManagerUser", id);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async toggleReceptionUser(id: string) {
    try {
      this.commit("setLoading", true);
      await UserService.toggleReceptionkUser(id);
      this.commit("setReceptionUser", id);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
  async getStatisticsUser() {
    try {
      this.commit("setLoading", true);
      const response = await UserService.getStatisticsUsers();
      this.commit("setStatistics", response.data);
    } catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    } finally {
      this.commit("setLoading", false);
    }
  }
}
