import { Getters } from "vuex-smart-module";
import hotelModuleState from "@/store/modules/hotelModule/state";

export default class hotelModuleGetters extends Getters<hotelModuleState> {
  get getHotel() {
    return this.state.hotel;
  }
  get getHotelListData() {
    return this.state.hotelList;
  }
  get getLoadingHotel() {
    return this.state.isLoading;
  }
  get getStatistics() {
    return this.state.statistics;
  }
}
