import { Mutations } from "vuex-smart-module";
import {ISlot} from "@/models/ISlot";
import slotModuleState from "@/store/modules/slotModule/state";

export default class slotModuleMutations extends Mutations<slotModuleState> {
    setLoading(loading:boolean){
        this.state.isLoading = loading;
    }
    setSlots(slots:ISlot[]){
        this.state.slots = slots;
    }
    setSlot(slot:ISlot){
        this.state.slot = slot;
    }
    deleteSlot(slot:ISlot){
        const candidate = this.state.slots.find(x => x.id === slot.id);
        if(candidate){
            const candidateIndex = this.state.slots.indexOf(candidate);
            if(candidateIndex>=0){
                this.state.slots.splice(candidateIndex,1);
            }
        }
    }
    postSlot(slot:ISlot){
      const candidate = this.state.slots.find(x => x.id === slot.id);
      console.log(candidate);
      if(candidate){
        const candidateIndex = this.state.slots.indexOf(candidate);
        if(candidateIndex>=0){
          this.state.slots.splice(candidateIndex,1,slot);
        }
      }else{
        this.state.slots.push(slot);
      }
    }
    putSlot(slot:ISlot){
        const candidate = this.state.slots.find(x => x.id === slot.id);
        if(candidate){
            const candidateIndex = this.state.slots.indexOf(candidate);
            if(candidateIndex>=0){
                this.state.slots.splice(candidateIndex,1,slot);
            }
        }
    }
}
