











































import BellAlertOutline from "vue-material-design-icons/BellAlertOutline.vue";
import NavBarComponent from "./components/NavBarComponent.vue";
import { mapGetters } from "vuex";
import LoadingComponent from "./components/LoadingComponent.vue";
export default {
  name: "app",
  components: {
    BellAlertOutline,
    NavBarComponent,
    LoadingComponent,
  },
  computed: {
    ...mapGetters("adminModule", ["getAuth", "getUser"]),
  },
};
