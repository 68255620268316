import { Getters } from "vuex-smart-module";
import menuTemplateModuleState from "@/store/modules/menuTemplateModule/state";

export default class menuTemplateModuleGetters extends Getters<menuTemplateModuleState> {
  get getMenuTemplateCategories(){
    return this.state.categories;
  }
  get getMenuTemplates(){
    return this.state.templates;
  }
}
