import { Mutations } from "vuex-smart-module";
import adminModuleState from "@/store/modules/adminModule/state";
import { IAdmin } from "@/models/IAdmin";

export default class adminModuleMutations extends Mutations<adminModuleState> {
  setAuth(bool: boolean) {
    this.state.isAuth = bool;
  }
  setAdmin(admin: IAdmin) {
    this.state.admin = admin;
  }
  setLoading(bool: boolean) {
    this.state.isLoading = bool;
  }
}
