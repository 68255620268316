import { Mutations } from "vuex-smart-module";
import hotelModuleState from "@/store/modules/hotelModule/state";
import { IHotel } from "@/models/IHotel";

export default class hotelModuleMutations extends Mutations<hotelModuleState> {
  setLoading(bool: boolean) {
    this.state.isLoading = bool;
  }
  setHotel(hotel: IHotel) {
    this.state.hotel = hotel;
  }
  setHotelList(hotelList: IHotel[]) {
    this.state.hotelList = hotelList;
  }
  setDeleteHotel(id: number) {
    const candidate = this.state.hotelList.find((x) => x.id === id);
    if (candidate) {
      this.state.hotelList.splice(this.state.hotelList.indexOf(candidate), 1);
    }
  }
  setStatistics(statistic: []) {
    this.state.statistics = statistic;
  }
}
