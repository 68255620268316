import { Module } from "vuex-smart-module";
import menuTemplateModuleState from "@/store/modules/menuTemplateModule/state";
import menuTemplateModuleGetters from "@/store/modules/menuTemplateModule/getters";
import menuTemplateModuleMutations from "@/store/modules/menuTemplateModule/mutations";
import menuTemplateModuleActions from "@/store/modules/menuTemplateModule/actions";
export const menuTemplateModule = new Module({
  state: menuTemplateModuleState,
  getters: menuTemplateModuleGetters,
  mutations: menuTemplateModuleMutations,
  actions: menuTemplateModuleActions,
});
