<template>
  <div class="navBarComponent">
    <div class="navBarComponentTop">
      <div class="navBarComponentTopLeft">
        <VueNestedMenu :source="menu" />
      </div>
      <div class="navBarComponentTopRight">
        <div class="navBarComponentTopRightNotification">
          <Bell class="navBarComponentTopRightNotificationIcon" :size="30" />
        </div>
        <div
          class="navBarComponentTopRightProfile"
          v-on:mouseover="navBarComponentTopRightProfileDropdown = true"
          @mouseleave="navBarComponentTopRightProfileDropdown = false"
        >
          <img
            class="navBarComponentTopRightProfileImage"
            src="https://dboard.guest.mobi/profile/default.png"
            alt=""
          />
          <div
            v-if="navBarComponentTopRightProfileDropdown"
            class="navBarComponentTopRightProfileDropdown"
          >
            <!--            <div-->
            <!--              class="navBarComponentTopRightProfileDropdownItem"-->
            <!--              @click="logout"-->
            <!--            >-->
            <!--              Аккант-->
            <!--            </div>-->
            <div
              class="navBarComponentTopRightProfileDropdownItem"
              @click="logout"
            >
              Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bell from "vue-material-design-icons/BellOutline.vue";
import { mapGetters, mapActions } from "vuex";
import VueNestedMenu from "vue-nested-menu/src/App";

export default {
  name: "NavBarComponent",
  data() {
    return {
      navBarComponentTopRightProfileDropdown: false,
      menu: {
        title: "Guesteco.com",
        children: [
          {
            title: "Home",
            link: "/",
            children: [],
          },
          {
            title: "Hotels",
            link: "/hotels",
            children: [],
          },
          {
            title: `Users`,
            link: "/users",
            children: [],
          },
          {
            title: "Snapshots",
            link: "/snapshots",
            children: [],
          },
          {
            title: `Statistics`,
            link: "/statistics",
            children: [],
          },
          {
            title: "Templates",
            children: [
              {
                title: "Project",
                link: "/templates",
                children: [],
              },
              {
                title:"Menu",
                link:"/menu-templates",
                children: [],
              },
              {
                title:"Labels",
                link:"/label-templates",
                children: []
              }
            ],
          },
          {
            title: "Slots",
            link:"/slots",
            children:[],
          },
          {
            title: "Labels",
            link:"/label-categories",
            children:[],
          }
        ],
      },
    };
  },
  components: {
    Bell,
    VueNestedMenu,
  },
  computed: {
    ...mapGetters("adminModule", ["getUser"]),
  },
  methods: {
    ...mapActions("adminModule", ["logout"]),
  },
};
</script>

<style lang="scss">
.navBarComponent {
  position: relative;
  z-index: 2;
}
.Menu {
  .Menu__header {
    background-color: #0250c5;
  }
  .Menu__item:hover {
    fill: #0250c5;
    color: #0250c5;
    a {
      color: #0250c5;
    }
  }
  .Menu__burger {
    fill: #999;
    &:hover {
      fill: #0250c5;
    }
  }
}

.navBarComponentTop {
  position: sticky;
  top: 0;
  min-height: 56px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;

  &Left {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  &Right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;

    &Notification {
      padding: 0 5px;
      &Icon {
        color: #999;
        cursor: pointer;

        &:hover {
          color: #0250c5;
        }
      }
    }

    &Profile {
      position: relative;

      &Image {
        cursor: pointer;
        max-width: 36px;
        height: auto;
        border-radius: 100%;
        border: 2px solid #999;

        &:hover {
          border: 2px solid #0250c5;
        }
      }

      &Dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 1000;
        min-width: 10rem;
        font-size: 0.875rem;
        text-align: center;
        list-style: none;
        background-clip: padding-box;
        border: 1px solid #999;
        border-radius: 0.25rem;
        color: #666;
        background-color: #fff;
        &Item {
          cursor: pointer;
          padding: 0.5rem 0;
          border-bottom: 1px solid #999;
          &:last-child {
            border: 0;
          }
          &:hover {
            color: #fff;
            background: #0250c5;
          }
        }
      }
    }
  }
}
</style>
