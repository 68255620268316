import { Actions } from "vuex-smart-module";
import labelModuleState from "@/store/modules/labelModule/state";
import labelModuleGetters from "@/store/modules/labelModule/getters";
import labelModuleMutations from "@/store/modules/labelModule/mutations";
import Vue from "vue";
import {LabelTemplateCategoryService} from "@/services/LabelTemplateService";
import {ILabelCategory} from "@/models/ILabelCategory";

export default class labelModuleActions extends Actions<
  labelModuleState,
  labelModuleGetters,
  labelModuleMutations,
  labelModuleActions
> {
  async getCategories(hotel_id:number){
    try {
      this.commit("setLoading", true);
      const {data} = await LabelTemplateCategoryService.getCategoriesList(hotel_id);
      this.commit('setCategories',data);
    }
    catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    }
    finally {
      this.commit("setLoading", false);
    }
  }
  async getCategory(id:number){
    try {
      this.commit("setLoading", true);
      const {data} = await LabelTemplateCategoryService.getCategory(id);
      this.commit('setCategory',data);
    }
    catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    }
    finally {
      this.commit("setLoading", false);
    }
  }
  async postCategory(category:ILabelCategory){
    try {
      this.commit("setLoading", true);
      const {data} = await LabelTemplateCategoryService.postCategory(category);
      this.commit('postCategory',data);
    }
    catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    }
    finally {
      this.commit("setLoading", false);
    }
  }
  async putCategory(category:ILabelCategory){
    try {
      this.commit("setLoading", true);
      const {data} = await LabelTemplateCategoryService.putCategory(category);
      this.commit('putCategory',data);
    }
    catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    }
    finally {
      this.commit("setLoading", false);
    }
  }
  async deleteCategory(id:number){
    try {
      this.commit("setLoading", true);
      const {data} = await LabelTemplateCategoryService.deleteCategory(id);
      this.commit('deleteCategory',data);
    }
    catch (e) {
      if (e.name == "SyntaxError") {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.message,
        });
      } else {
        Vue.notify({
          group: "alert",
          title: "Ошибка",
          text: e.response?.data?.message,
        });
      }
    }
    finally {
      this.commit("setLoading", false);
    }
  }

}
